import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsRelation from './components/LanguageOptionsRelation';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';


const content1 = {
  title: "Key Responsibilities:",
  content: [
          "- Serve as the Relationship Manager for the Data Provider Community, engaging with leading trading firms and exchanges globally",
          "- Collaborate with Community Managers to boost engagement through event participation, enhancing social presence, and improving brand visibility",
          "- Assist the technical integration team in fully onboarding data providers to the main network and broadening the supported asset classes",
          "- Monitor system performance",
          "- Facilitate due diligence and onboarding for new participants on the network",

    ]
}

const content2 = {
  title: "Required Skills And Qualifications:",
  content: [
            "- A minimum of 2 years of experience in a finance or business development role within fintech startups (e.g., banks, exchanges, crypto trading)",
            "- Experience with trading firms, trading desks, buy/sell side trading, or investment banking is highly relevant",
            "- General understanding of cryptocurrency, including wallet usage, sending/receiving tokens, tracking transactions on a block explorer, and staking",
    ]
}

const companyURL = "https://t-force.com/";


function AppTforce() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="Remote" salary="$200K + Bonus + Tokens" company="T-Force Group" companyURL={companyURL} />
          <LanguageOptionsRelation company="T-Force Group" title="Relationship Manager - Crypto" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppTforce;
