import React from 'react';
import { Link } from "react-router-dom";
import './StepBar.css'

const StepTraderBar = ({ className, index }) => {
  return (
    <div className={`step-bar-container flex items-center justify-between ${className}`}>
      <Link to="/video-questions/create/er3k73b8048c3dbb6726df1efd5901jd" className={`circle ${index == 1 ? "active-step" : "passed-step"}`}>1</Link>
      <div className="line grow" style = {{width: "500px"}}></div>
      <Link to="/video-questions/create/8er3od939w8420c41a4b4da92335lpw0" className={`circle ${index == 2 ? "active-step" : index == 3? "passed-step" : ""}`}>2</Link>
      <div className="line grow" style = {{width: "500px"}}></div>
      <Link to="/video-questions/create/ie983jdedf67046d6d3kid983jjd9kef" className={`circle ${index == 3 ? "active-step" : ""}`}>3</Link>

    </div>
  );
};

export default StepTraderBar;
