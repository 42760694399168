import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsBusiness from './components/LanguageOptionsBusiness';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';




const content1 = {
  title: "Key Responsibilities:",
  content: [
    "- Lead Generation: Identify, research, and engage high-quality developers and teams that align with Halliday’s offerings. ",
    "- Outbound Prospecting: Leverage outbound sales techniques—including email campaigns, social media outreach, and industry events—to connect with potential clients and generate interest. ",
    "- Lead Qualification: Conduct discovery conversations to understand developers' needs, assess fit, and position Halliday’s solutions effectively. ",
    "- Research-Driven Outreach: Thoroughly research prospects to craft personalized messaging that aligns Halliday’s value proposition with the priorities of key decision-makers.",
    "- CRM Management: Maintain accurate, up-to-date records of prospects and leads within the CRM, ensuring timely follow-ups and seamless tracking of the sales pipeline. ",
    "- Experience trading in a hedge fund or other proprietary trading business",
    "- Relationship Management: Build and maintain strong relationships within the ecosystem, including RAAS platforms, blockchain networks, and developers working on modular chains. "
]
}

const content2 = {
  title: "Required Qualifications:",
  content: [
            "- Experience: 3+ years in business development or growth roles, with a focus on crypto or blockchain projects.", 
            "- Sales Expertise: Proven track record in sales, lead generation, and managing the end-to-end sales process.", 
            "- Communication Skills: Outstanding written and verbal communication, with the ability to engage and influence stakeholders across multiple levels of an organization.",
            "- Adaptability: A quick learner who thrives in ambiguous, fast-paced environments.",
            "- Solution Selling: Demonstrated success in selling complex solutions, ideally within the blockchain or tech space. "    ]
}

const companyURL = "https://www.halliday.xyz";


function AppHalliday() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="Remote" salary="$300K/yr - $350K/yr" company="Halliday" companyURL={companyURL} />
          <LanguageOptionsBusiness company="Halliday" title="Business Development Lead" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppHalliday;
