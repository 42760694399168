import React from 'react';
import { Link } from "react-router-dom";
import './StepBar.css'

const StepDigitalAssetsBar = ({ className, index }) => {
  return (
    <div className={`step-bar-container flex items-center justify-between ${className}`}>
      <Link to="/video-questions/create/xfigi3k73b8048c3dbbdfds26df1ef23542d" className={`circle ${index == 1 ? "active-step" : "passed-step"}`}>1</Link>
      <div className="line grow" style = {{width: "500px"}}></div>
      <Link to="/video-questions/create/figiod939w8420c41asdfa92335lpw0df4tt" className={`circle ${index == 2 ? "active-step" : index == 3? "passed-step" : ""}`}>2</Link>
      <div className="line grow" style = {{width: "500px"}}></div>
      <Link to="/video-questions/create/32iewi3jdedf67046d324sdf24h83jjd9kef" className={`circle ${index == 3 ? "active-step" : ""}`}>3</Link>

    </div>
  );
};

export default StepDigitalAssetsBar;
