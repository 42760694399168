import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsDigitalAssets from './components/LanguageOptionsDigitalAssets';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';

const content1 = {
  title: "Responsibilities:",
  content: [
          "- Identify and engage in new regional business and strategic partnerships across the United States, Canada, Latin America and Asia --primarily focusing on liquidity provisioning for projects in these areas.",
          "- Develop, assess, and implement regional business development strategies, while aiding in the establishment of operations in new areas, ensuring adherence to local regulatory standards.",
          "- Establish and strengthen connections with key players in the digital asset ecosystem, including venture firms, exchanges, custodians, lenders, infrastructure providers, and both crypto-native and institutional OTC entities within AMER.",
          "- Represent Bitget at conferences and other industry events.",
    ]
}

const content2 = {
  title: "Desired Skills and Experience:",
  content: [
            "- Bachelor’s or Master’s degree in Business, Economics, Finance, or a related field",
            "- 4+ years relevant experience in the crypto ecosystem within high-performance teams and institutions",
            "- A large network of crypto native players (protocols/projects/tokens/investors)",
            "- Strong expertise in a specific sector of DeFi, GameFi, SocialFi, or Infrastructure in the digital asset space",
            "- Enthusiasm and the capability to grasp the fundamentals of blockchain technologies, algorithmic trading, and liquidity provisioning",
            "- An entrepreneurial, solution driven mindset"
    ]
}

const companyURL="https://www.bitget.com/";

function AppDigitalAssetsAmes() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
    
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="United States (Remote)" salary="$250K + Bonus + Tokens" company="Bitget" companyURL={companyURL} />
          <LanguageOptionsDigitalAssets company="Bitget" title="Digital Assets Business Development Manager" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppDigitalAssetsAmes;
